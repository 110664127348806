<template>
  <div class="w-100">
    <pibot-page-heading>
      IRM Optimisation
    </pibot-page-heading>

    <v-container>
      <pibot-optimisation-budget-planning :colorScheme="['#80BCB0', '#CCCCCC', '#11526C']" class="mb-3" />

      <pibot-optimisation-cost-distribution class="mb-3" />
    </v-container>
  </div>
</template>

<script>
import PageHeading from '@/components/typography/headings/PageHeading'

export default {
  name: 'pibot-irm-optimisation',
  components: {
    'pibot-page-heading': PageHeading,
    'pibot-optimisation-budget-planning': () => import('./BudgetPlanning'),
    'pibot-optimisation-cost-distribution': () => import('./CostDistribution')
  }
}
</script>
